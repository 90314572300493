import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import loadable from '@loadable/component';
import {
  BackgroundWrapper,
  flexColContainerStyles,
  flexRowContainerStyles,
  RowContainer,
} from 'commonStyles';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import Cta from 'components/Cta';
import { getGutter, TGutter } from 'components/utils';
import { MEDIA_QUERIES } from 'globalStyles';
import 'rc-slider/assets/index.css';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeFunctionalColor,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import hideable from 'utils/hideable';
import { IRadioButton } from './types';
import { themev2 } from 'theme-v2';
const Slider = loadable(() => import('rc-slider'), { ssr: false });

const fadeOut = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const AnimationWrapper = styled.div({
  animation: `${fadeOut} 1s`,
});

const getRadioBorder = (
  isSelected: boolean,
  colorShade: COLOR_SHADE,
  theme: ITheme
) =>
  isSelected
    ? `8px solid ${getThemeFunctionalColor(theme, colorShade)}`
    : `1px solid ${getThemeMainColor(theme, MAIN_COLOR.BLACK)}`;

export const PlanFeaturesContainer = styled.div<ITheme>((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
}));

export const PlanFeature = styled.div<ITheme>((props) => ({
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  fontWeight: 700,
  fontSize: themev2.fonts.size.p_small,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    fontSize: themev2.fonts.size.p_xsmall,
  },
}));

export const PlanFeatures = styled(RowContainer)({
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  div: {
    display: CSS_DISPLAY.FLEX,
    span: {
      fontWeight: 400,
      marginRight: '3px',
      alignSelf: CSS_ALIGN.CENTER,
      textDecoration: 'line-through',
    },
  },
});

export const PlanAmount = styled.div<ITheme & { colorShade: COLOR_SHADE }>(
  ({ colorShade, ...props }) => ({
    lineHeight: '50px',
    fontWeight: 600,
    fontSize: getThemeFontSize(props, TEXT_TYPE.LARGE),
    color: getThemeFunctionalColor(props, colorShade),
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      fontSize: '42px',
    },
  })
);

export const PlanPrice = styled.div<ITheme>((props) => ({
  fontWeight: 600,
  fontSize: getThemeFontSize(props, TEXT_TYPE.H1),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
}));

export const InfoText = styled.div<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),
  lineHeight: '20px',
  marginTop: getThemeGutter(props),
}));

export const StyledCta = styled(Cta)<ITheme>((props) => ({
  minWidth: '200px',
  height: '44px',
  margin: 0,
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
  },
}));

export const StyledCtaContainer = styled.div({
  marginLeft: 'auto',
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
  },
});

export const PlanContainer = styled.div<ITheme>((props) => ({
  position: CSS_POSITION.RELATIVE,
  color: getThemeBrandedColor(props, COLOR_SHADE.SECONDARY),
  marginRight: getThemeGutter(props, TSHIRT_SIZE.L),
  width: '230px',
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
    marginRight: '0',
  },
}));

export const Container = styled.div<ITheme>((props) => ({
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  backgroundColor: getThemeMainColor(props),
  boxShadow: '0 0 24px 0 rgb(0 0 0 / 10%)',
  maxWidth: '1024px',
  width: '100%',
  display: CSS_DISPLAY.GRID,
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  rowGap: '16px',
  gridTemplateRows: 'auto',
  borderRadius: '12px',
  gridTemplateColumns: '230px repeat(auto-fit, 70%)',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  minHeight: '287px',
  [MEDIA_QUERIES.TABLET]: {
    height: 'unset',
    gridTemplateColumns: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    rowGap: getThemeGutter(props, TSHIRT_SIZE.S),
    width: '50%',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '80%',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
}));

export const RadioButton = styled.div<ITheme & IRadioButton>(
  ({ isSelected, colorShade, ...props }) => ({
    width: '24px',
    height: '24px',
    border: getRadioBorder(isSelected, colorShade, props),
    borderRadius: '12px',
    transition: 'all 0.1s 0s ease',
    [DOM_STATE.HOVER]: {
      borderWidth: isSelected ? 'none' : '2px',
    },
  })
);

export const RadioContainer = styled.div<TGutter>((props) => ({
  margin: getGutter(props),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XXS),
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  cursor: 'pointer',
}));

export const RadioText = styled.span<ITheme>((props) => ({
  color: getThemeBrandedColor(props, COLOR_SHADE.SECONDARY),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  fontWeight: 700,
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  marginTop: '2px',
  marginLeft: getThemeGutter(props),
}));

export const BottomContainer = styled.div({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  [MEDIA_QUERIES.TABLET]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    alignItems: CSS_ALIGN.FLEX_START,
  },
});

export const ContentContainer = styled.div({
  ...flexColContainerStyles,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_END,
});

export const Scale = styled.div({
  height: '36px',
  marginTop: '13px',
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  marginBottom: '46px',
  [MEDIA_QUERIES.TABLET]: {
    marginBottom: '40px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginBottom: '36px',
  },
});

export const ScaleItem = styled.div({
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
  width: '25%',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
});

export const ScaleText = styled.span<ITheme>((props) => ({
  fontWeight: 600,
  fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
}));

export const HandleText = styled.span<ITheme>((props) => ({
  color: getThemeBrandedColor(props, COLOR_SHADE.SECONDARY),
  fontWeight: 600,
  fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XXXL),
}));

export const BlockContainer = styled(BackgroundWrapper)<ITheme>((props) => ({
  ...flexColContainerStyles,
  maxWidth: 'unset',
  padding: '0 20px',
  marginTop: 0,
  zIndex: 1,
  [MEDIA_QUERIES.TABLET]: {
    paddingBottom: getThemeInputPaddingShape(props, TSHIRT_SIZE.S),
  },
}));

export const RadioButtonsContainer = styled.div<ITheme>((props) => ({
  ...flexRowContainerStyles,
  [MEDIA_QUERIES.TABLET]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    ...flexColContainerStyles,
  },
}));

export const HideableRadioButtonsContainer = hideable(RadioButtonsContainer);

export const TitleWrapper = styled.div<ITheme>({
  textAlign: TEXT_ALIGN.CENTER,
  'h1, h2, h3': {
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      lineHeight: '48px',
    },
  },
  'h1, h2, h3, p': {
    marginBottom: 0,
  },
});

export const TitleWrapperResidential = styled.h2<ITheme>((props) => ({
  textAlign: TEXT_ALIGN.CENTER,
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H2),
  fontSize: getThemeFontSize(props, TEXT_TYPE.H2),
  fontWeight: 500,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    lineHeight: '48px',
  },
}));

export const PlanTitle = styled.div<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
  lineHeight: '20px',
  fontWeight: 600,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  [MEDIA_QUERIES.TABLET]: {
    fontSize: '24px',
  },
}));

export const ResidentialPlanTitle = styled.div<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
  lineHeight: '24px',
  fontWeight: 600,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
}));

export const NeedMoreTitle = styled.span<ITheme>((props) => ({
  fontWeight: 600,
  fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
}));

export const NeedMoreDescription = styled.span<ITheme>((props) => ({
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
}));

export const NeedMoreIcon = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  width: '50px',
  height: '50px',
}));

export const NeedMoreContainer = styled(AnimationWrapper)({
  ...flexColContainerStyles,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  textAlign: TEXT_ALIGN.CENTER,
  height: '100%',
  [MEDIA_QUERIES.TABLET]: {
    height: '282px',
  },
});

export const RangeContainer = styled.div({
  ...flexColContainerStyles,
  marginTop: '33px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginTop: '45px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginTop: '20px',
  },
});

export const StyledRangeContainer = styled.div({
  ...flexColContainerStyles,
  height: '41px',
  margin: '33px 0 46px',
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  [MEDIA_QUERIES.TABLET]: {
    marginBottom: '40px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginTop: '45px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    margin: '20px 0 36px',
  },
});

export const Range = styled(Slider)<ITheme & { colorShade: COLOR_SHADE }>(
  ({ colorShade, ...props }) => ({
    '.rc-slider-rail': {
      height: '8px',
      borderRadius: '4px',
      backgroundColor: getThemeNeutralColor(props, COLOR_SHADE.PRIMARY),
    },
    '.rc-slider-track': {
      width: '100%',
      height: '8px',
      borderRadius: '4px',
      backgroundColor: getThemeFunctionalColor(props, colorShade),
    },
    '.rc-slider-handle': {
      display: CSS_DISPLAY.FLEX,
      alignItems: CSS_ALIGN.CENTER,
      justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
      backgroundColor: getThemeMainColor(props),
      width: '30px',
      height: '30px',
      border: `10px solid ${getThemeFunctionalColor(props, colorShade)}`,
      borderRadius: '15px',
      cursor: 'pointer',
      top: '0',
      [DOM_STATE.HOVER_ACTIVE]: {
        borderWidth: '8px',
        borderColor: getThemeFunctionalColor(props, colorShade),
        boxShadow: 'none',
      },
      [DOM_STATE.FOCUS]: {
        borderColor: getThemeFunctionalColor(props, colorShade),
      },
    },
    '.rc-slider-step': {
      display: 'none',
    },
    '.rc-slider-mark': {
      marginTop: '13px',
      '.rc-slider-mark-text': {
        color: getThemeBrandedColor(props, COLOR_SHADE.SECONDARY),
        fontWeight: 600,
        fontSize: '12px',
      },
    },
  })
);

export const DiscountLabelComponent = styled.div((props) => ({
  position: CSS_POSITION.ABSOLUTE,
  left: '50%',
  top: '-24px',
  transform: 'translate(-30%, -50%)',
  padding: `0 ${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)}`,
  background: 'rgb(245, 191, 75)',
  borderRadius: '24px',
  margin: 0,
  lineHeight: '26px',
  fontSize: getThemeFontSize(props),
  color: 'rgb(33, 53, 72)',
  fontFamily: getThemeFontFamily(props),
  fontWeight: 500,
  [MEDIA_QUERIES.TABLET]: {
    transform: 'translate(-50%, -50%)',
  },
}));

export const CrossedOutPrice = styled.span<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
  lineHeight: '36px',
  color: getThemeNeutralColor(props, COLOR_SHADE.SECONDARY),
  display: CSS_DISPLAY.BLOCK,
  textDecoration: 'line-through',
  fontWeight: 400,
  marginTop: '-24px',
}));

export const HideableCrossedOutPrice = hideable(CrossedOutPrice);
export const HideableHandleText = hideable(HandleText);
export const HideableTitle = hideable(PlanTitle);
export const HideableResidentialTitle = hideable(ResidentialPlanTitle);
export const HideableAnimationWrapper = hideable(AnimationWrapper);
export const HideableTitleWrapper = hideable(TitleWrapper);
